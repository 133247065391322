<template>
    <div class="flex items-stretch relative" :class="{ 'col-span-2': item.width === 2 }">
        <div v-if="item.type === 'chart' && item.chart_options" class="bg-white relative pt-4 shadow-md rounded-lg px-2 w-full max-w-full">
            <div
                v-if="item.help_link"
                class="absolute top-4 z-10"
                :class="{
                    'right-[94px]': item.display_magnifier && item.burger_menu?.is_menu_applied === true,
                    'right-[56px]': (!item.display_magnifier && item.burger_menu?.is_menu_applied === true) || (item.display_magnifier && !item.burger_menu?.is_menu_applied),
                    'right-4': !item.display_magnifier && !item.burger_menu?.is_menu_applied,
                }"
            >
                <a :href="item.help_link" target="_blank" class="flex items-center justify-center p-1 rounded-full transition-colors hover:bg-gray-100">
                    <span class="sr-only">{{ item.help_link }}</span>
                    <Icon class="h-6 w-auto" :src="IconSource.HelpCircleIcon"></Icon>
                </a>
            </div>
            <div v-if="item.burger_menu?.is_menu_applied && item.burger_menu?.options?.length" class="absolute top-4 right-4 z-10">
                <ChartMultiSelect v-model:value="selectedOptions" :items="item.burger_menu.options" :disabled="loading" item-text="name" item-value="query_parameter" @closing="onOptionsMenuClosing" />
            </div>
            <EmptyChartState :options="item.chart_options" class="w-full grid">
                <GenericChart
                    destroy-on-update
                    :options="item.chart_options"
                    :has-options="item.burger_menu?.is_menu_applied === true"
                    :expandable="item.display_magnifier"
                    @ready="$emit('update:chart', $event)"
                    @update="$emit('update:chart', $event)"
                />
            </EmptyChartState>
        </div>
        <div v-else-if="item.type === 'table' && item.table" class="bg-white relative shadow-md rounded-lg w-full max-w-full overflow-hidden">
            <GenericTable :table-data="item.table" :help-link="item.help_link" narrow />
        </div>
        <div v-else-if="item.type === 'text' && item.text_content" class="w-full max-w-full space-y-1 mt-6 mb-2">
            <div class="font-bold text-lg leading-tight">{{ item.text_content.title }}</div>
            <div v-if="item.text_content.subtitle" class="text-gray-500 leading-tight">{{ item.text_content.subtitle }}</div>
            <div v-if="item.text_content.description" class="text-sm text-gray-500">{{ item.text_content.description }}</div>
        </div>
        <transition name="fade-fast" mode="out-in">
            <div v-if="loading" class="absolute inset-0 z-30 flex items-center justify-center bg-white/70">
                <Spinner class="h-8 w-8" />
            </div>
        </transition>
    </div>
</template>

<script setup lang="ts">
import * as Highcharts from 'highcharts';
import { ref, watch } from 'vue';
import { DashboardItem, DashboardBurgerMenuOption, IconSource } from '@/types';
import ChartMultiSelect from '@/components/ui/ChartMultiSelect.vue';
import EmptyChartState from '@/components/charts/EmptyChartState.vue';
import GenericChart from '@/components/charts/GenericChart.vue';
import GenericTable from '@/components/common/GenericTable.vue';
import Spinner from '@/components/ui/Spinner.vue';

interface Props {
    item: DashboardItem;
    loading?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    loading: false,
});
const emit = defineEmits<{
    (e: 'update:chart', instance: Highcharts.Chart): void;
    (e: 'update:item', value: DashboardItem): void;
    (e: 'update:options', value: { options: DashboardBurgerMenuOption[]; hasChanged: boolean }): void;
}>();

const selectedOptions = ref(props.item.burger_menu?.options.filter((option) => option.is_applied) || []);

watch(
    () => props.item.burger_menu,
    (value) => {
        selectedOptions.value = value?.options.filter((option) => option.is_applied) || [];
    }
);

function onOptionsMenuClosing() {
    let hasChanged = (props.item.burger_menu?.options.filter((option) => option.is_applied) || []).length !== selectedOptions.value.length;

    for (const option of selectedOptions.value) {
        const propOption = props.item.burger_menu?.options.find((o) => o.query_parameter === option.query_parameter);

        if (!propOption) {
            hasChanged = true;
        }
    }

    emit('update:options', {
        options: selectedOptions.value,
        hasChanged,
    });
}
</script>
