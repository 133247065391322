import { keepPreviousData, useQuery, type UseQueryOptions } from '@tanstack/vue-query';
import type { Ref } from 'vue';
import ApiRequest from '@/plugins/store/actions/api/ApiRequest';
import { Dashboard } from '@/types';

const DEFAULT_OPTIONS: UseQueryOptions<any> = {
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 5, // 5 minutes
};

export const queryKeys = {
    getDashboardRoot: ['dashboards', 'analysis'],
    getDashboard: ({ typeId, isWeaners, scope, locationIds, period }: { typeId: Ref<string>; isWeaners: Ref<boolean | null>; scope: Ref<number>; locationIds: Ref<number[]>; period: Ref<string> }) => [
        ...queryKeys.getDashboardRoot,
        { typeId, isWeaners, scope, locationIds, period },
    ],
};

export function useAnalysisDashboard(
    {
        typeId,
        isWeaners,
        scope,
        locationIds,
        period,
    }: {
        typeId: Ref<string>;
        isWeaners: Ref<boolean | null>;
        scope: Ref<number>;
        locationIds: Ref<number[]>;
        period: Ref<string>;
    },
    options: UseQueryOptions<Dashboard> = {}
) {
    return useQuery<Dashboard>({
        queryKey: queryKeys.getDashboard({ typeId, isWeaners, scope, locationIds, period }),
        queryFn: () => {
            let query = `?${period.value}&type_id=${typeId.value}&scope=${scope.value}&location_ids=${locationIds.value.toString()}&initialrequest=true`;

            if (isWeaners.value !== null) {
                query += `&isweaners=${isWeaners.value}`;
            }

            return new ApiRequest().send('get', `dashboards/analysis${query}`);
        },
        placeholderData: keepPreviousData,
        ...DEFAULT_OPTIONS,
        ...options,
    });
}
